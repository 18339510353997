import React from "react";
import nointernetImg from "../../assets/images/Icons/noInternet1.png";

export default function NoInternetConnection() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-md-6 text-center">
          <h1 className="text-center modal-text-que gilroysemibold">
            Something went wrong
          </h1>
          <p className="text-center modal-text-que gilroyregular mb-4">
            Couldn&apos;t fetch the widget.try again
          </p>
          <img
            src={nointernetImg}
            className="img-fluid"
            alt="Image"
            style={{ height: "70px", width: "70px" }}
          />
          <br />
        </div>
      </div>
    </div>
  );
}
