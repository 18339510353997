/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import NodataFound from "../comman/NodataFound";
import API from "../../Api/Api";

export default function Settings() {
  const [userList, setuserList] = useState(null);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all_users");
  const [meetingDetails, setMeetingDetails] = useState(null);

  const getUsersList = () => {
    if (selectedTab === "all_users") {
      API.Setting.getAllSettingListAPI({
        data: {
          all_users: true,
        },
      })
        .then((response) => {
          console.log(response?.data?.data?.otherDepartments);
          setuserList(response?.data?.data?.otherDepartments);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "active_users") {
      API.Setting.getAllSettingListAPI({
        data: {
          active_users: true,
        },
      })
        .then((response) => {
          console.log(response);
          const filteredActiveData =
            response?.data?.data?.otherDepartments?.filter(
              (item) => item?.profileSetup === true && item?.joined === true
            );
          setuserList(filteredActiveData);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "inactive_users") {
      API.Setting.getAllSettingListAPI({
        data: {
          inactive_users: true,
        },
      })
        .then((response) => {
          console.log(response);
          setuserList(response?.data?.data?.otherDepartments);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "pending_users") {
      API.Setting.getAllSettingListAPI({
        data: {
          pending_users: true,
        },
      })
        .then((response) => {
          console.log(response);
          setuserList(response?.data?.data?.otherDepartments);
        })
        .catch((err) => console.log(err));
    }
  };

  const getMeetingDetails = () => {
    API.Meeting.loadMeetingDetails()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("Response is here:", response.data.data);
          setMeetingDetails(response.data.data);
        } else {
          console.log("Unexpected status code:", response.data.statusCode);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.statusCode === 403) {
          navigate("/");
        } else {
          console.log("Error response:", err.response);
          console.log("Error message:", err.message);
        }
      });
  };

  // AM and PM Logic
  const formatMeetingPeroid = (meetingTime) => {
    if (!meetingTime) return "";
    const [hour, minute] = meetingTime.split(":");
    const hourNum = parseInt(hour, 10);
    const period = hourNum >= 12 ? "PM" : "AM";
    return `${period}`;
  };

  // AM and PM Logic
  const formatMeetingTime = (meetingTime) => {
    if (!meetingTime) return "";

    const [hour, minute, second] = meetingTime.split(":");
    let hourNum = parseInt(hour, 10);
    const period = hourNum >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    hourNum = hourNum % 12 || 12; // '|| 12' handles the case when hourNum is 0

    return `${hourNum} : ${minute}`;
  };

  useEffect(() => {
    // Fetch initial data when the component mounts
    getUsersList();
  }, [selectedTab]); // Add selectedTab to the dependency array

  useEffect(() => {
    getMeetingDetails();
    console.log("load meeting details API call");
  }, []);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  console.log("meetingDetails are here", meetingDetails);
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">Settings</h3>
              </div>
            </div>
            <ul
              className="nav nav-tabs nav-tabs-custom mt-4 border-0"
              id="mainTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active ps-0"
                  data-bs-toggle="tab"
                  href="#User"
                  role="tab"
                >
                  <h3 class="fw-bold">User Management</h3>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-bs-toggle="tab"
                  href="#Score"
                  role="tab"
                >
                  <h3 class="fw-bold">Score Management</h3>
                </a>
              </li>
            </ul>
            <div className="tab-content text-muted mt-3">
              <div className="tab-pane active" id="User" role="tabpanel">
                <div className="row">
                  <div className="col-12">
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-sm-5 mt-3 mt-xl-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#all_users"
                          role="tab"
                          onClick={() => handleTabClick("all_users")}
                        >
                          <span>All Users</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#active_users"
                          role="tab"
                          onClick={() => handleTabClick("active_users")}
                        >
                          <span>Active Users</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#inactive_users"
                          role="tab"
                          onClick={() => handleTabClick("inactive_users")}
                        >
                          <span>Inactive Users</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#pending_users"
                          role="tab"
                          onClick={() => handleTabClick("pending_users")}
                        >
                          <span>Pending Users</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active"
                        id={selectedTab}
                        role="tabpanel"
                      >
                        {userList?.length > 0 ? (
                          <div className="row mt-4">
                            {userList &&
                              userList?.map((item, index) => {
                                return (
                                  <div className="col-xl-4 mb-3" key={index}>
                                    <NavLink
                                      className="text-decoration-none"
                                      // to={`/settings-details/${item?._id}`}
                                    >
                                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={departmentImg}
                                            className="me-3 swiper-slide-img"
                                          />
                                          <div>
                                            <h3 className="textDarkBlack mb-1 fw-bold msm">
                                              {item?.dept_name}
                                            </h3>
                                            <p className="lightGrey mb-0">
                                              {item?.fullName
                                                ? item?.fullName
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {/* {selectedTab === "pending_users" &&
                                    item?.joined === false ? (
                                      <p className="mb-0 pendingStatus">
                                        Pending
                                      </p>
                                    ) : selectedTab === "inactive_users" &&
                                      item?.isDepartmentActive === false ? (
                                      <p className="mb-0 inactiveStatus">
                                        Inactive
                                      </p>
                                    ) : selectedTab === "active_users" &&
                                      item?.isDepartmentActive === true ? (
                                      <p className="mb-0 activeStatus">
                                        Active
                                      </p>
                                    ) : selectedTab === "all_users" ? (
                                      <p
                                        className={`mb-0 ${
                                          item?.isDepartmentActive
                                            ? "activeStatus"
                                            : "inactiveStatus"
                                        }`}
                                      >
                                        {item?.isDepartmentActive
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                    ) : null} */}
                                        {item?.isDepartmentActive === true &&
                                        item?.joined === true ? (
                                          <p className="mb-0 activeStatus">
                                            Active
                                          </p>
                                        ) : item?.isDepartmentActive ===
                                          false ? (
                                          <p className="mb-0 inactiveStatus">
                                            Inactive
                                          </p>
                                        ) : item?.isDepartmentActive === true ||
                                          item?.joined === false ? (
                                          <p className="mb-0 pendingStatus">
                                            Pending
                                          </p>
                                        ) : null}
                                      </div>
                                    </NavLink>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <NodataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="Score" role="tabpanel">
                <div className="row">
                  <div className="col-8">
                    <div className="row mt-4">
                      <div className="col-12">
                        <h4 className="textDarkBlack fw-bold">Meeting Time</h4>
                        {/* <p className="textLightGrey">Select date and time for your weekly meeting</p> */}
                        <div className="row mb-3 mt-4">
                          <div className="col-6">
                            <label className="form-label textLightGrey">
                              Scheduled Day
                            </label>
                            <br />
                            <p className="bg-para w-50 textBlack">
                              {meetingDetails?.meeting_day}
                            </p>
                          </div>
                          <div className="col-6">
                            <label className="form-label textLightGrey">
                              Scheduled Time
                            </label>
                            <br />
                            <p
                              className="bg-para px-4 textBlack"
                              style={{ width: "fit-content" }}
                            >
                              {formatMeetingTime(meetingDetails?.meeting_time)}
                              <span className="lightGrey mx-2"> | </span>{" "}
                              {formatMeetingPeroid(
                                meetingDetails?.meeting_time
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <h4 className="textDarkBlack fw-bold">Time Slot</h4>
                        <div className="row mt-4">
                          <div className="col-6">
                            <label className="form-label textLightGrey">
                              Scheduled Time Slot
                            </label>
                            <p className="bg-para w-50 textBlack">
                              {meetingDetails?.time_slot_hours_before_meeting}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
