/* eslint-disable */

import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import alertImg from "../../assets/images/Icons/SVG/alert.svg";
import editIcon from "../../assets/images/Icons/SVG/editIcon.svg";
import { useFormik } from "formik";
import API from "../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setObjectiveData } from "../../Redux/Slice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DepartmentObjective from "./DepartmentObjective";
export default function DepartmentDetails() {
  const [departmentData, setDepartmentDataList] = useState(null);
  const [singleObjectiveData, setSingleObjectiveData] = useState(null);
  const [keyResultData, setKeyResultData] = useState(null);
  const [editedObjectives, setEditedObjectives] = useState([]);
  const [editedKeyResults, setEditedKeyResults] = useState([]);
  const [checkObjective, setCheckObjective] = useState(undefined);
  const [addNewObjective, setAddNewObjective] = useState(false);
  const [addNewObjName, setAddNewObjName] = useState();
  const [addNewObjDesc, setAddNewObjDesc] = useState();
  const [addNewTaskInitiative, setAddNewTaskInitiative] = useState();
  const [addNewKeyResult, setAddNewKeyResult] = useState();
  const [newObjNameError, setNewObjNameError] = useState();
  const [newObjDescError, setNewObjDescError] = useState();
  const [addNewTask, setAddNewTask] = useState(false);
  const [iconData, setIconData] = useState(null);
  const [onChangeObjectiveId, setOnChangeObjectiveId] = useState();
  const postData = useSelector((state) => state.date.departmentData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};
    const spaceReg = /^\S.*\S$/;
    const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

    if (!values.dept_objective_name) {
      errors.dept_objective_name = "Please enter name";
    } else if (!spaceReg.test(values.dept_objective_name)) {
      errors.dept_objective_name = "Cannot start & end with a space";
    }
    if (!values.dept_objective_description) {
      errors.dept_objective_description = "Please enter description";
    } else if (!paragraphReg.test(values.dept_objective_description)) {
      errors.dept_objective_description = "Cannot start & end with a space";
    }
    if (!values.objective_id) {
      errors.objective_id = "Please select objective";
    }
    return errors;
  };

  const EditObjValidate = (values) => {
    const errors = {};
    const spaceReg = /^\S.*\S$/;
    const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

    if (!values.add_new_obj_name) {
      errors.add_new_obj_name = "Please enter name";
    } else if (!spaceReg.test(values.add_new_obj_name)) {
      errors.add_new_obj_name = "Cannot start & end with a space";
    }
    if (!values.add_new_obj_description) {
      errors.add_new_obj_description = "Please enter description";
    } else if (!paragraphReg.test(values.add_new_obj_description)) {
      errors.add_new_obj_description = "Cannot start & end with a space";
    }
  };

  const formik = useFormik({
    initialValues: {
      dept_objective_name: "",
      dept_objective_description: "",
      objective_id: "",
    },
    onSubmit: () => {
      createDeptObjective();
    },
    validate,
  });

  const EditObjFormik = useFormik({
    initialValues: {
      add_new_obj_name: "",
      add_new_obj_description: "",
    },
    onSubmit: () => {
      handleNewObjSubmit();
    },
    EditObjValidate,
  });

  const { id } = useParams();
  const { type } = useParams();
  const getSingledepartmentById = () => {
    if (id) {
      API.Department.getDepartmentObjectiveList(id)
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDepartmentDataList(response.data.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/");
          }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    // Initialize tooltips
    const tooltips = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltips.map((tooltip) => {
      return new window.bootstrap.Tooltip(tooltip);
    });
  }, []);

  useEffect(() => {
    getSingledepartmentById();
  }, [id]);

  useEffect(() => {
    if (singleObjectiveData && singleObjectiveData.length > 0) {
      setEditedObjectives(singleObjectiveData?.map((item) => ({ ...item })));
    }
  }, [singleObjectiveData]);
  useEffect(() => {
    if (keyResultData && keyResultData.length > 0) {
      setEditedKeyResults(keyResultData?.map((item) => ({ ...item })));
    }
  }, [keyResultData]);

  // Create Department
  const createDeptObjective = () => {
    API.Department.createDepartment({
      data: {
        objective_id: formik.values.objective_id,
        dept_objective_name: formik.values.dept_objective_name,
        dept_objective_description: formik.values.dept_objective_description,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          formik.resetForm();

          // Check if the element exists before trying to click it
          if (formik.values.objective_id) {
            const addButton = document.querySelector(
              `#AddDepartment${formik?.values?.objective_id}`
            );
            if (addButton) {
              addButton.click();
            }
          }

          getSingledepartmentById();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleButtonClick = (objectiveId) => {
    formik.setFieldValue("objective_id", objectiveId);
  };

  const NeededInfo = (objName, KeyResult, description, index) => {
    const newdata = {
      objName: objName,
      KeyResult: KeyResult,
      description: description,
      // percentage: percentage,
      index: index,
    };
    dispatch(setObjectiveData(newdata));
  };

  const handleEditObjectiveSubmission = () => {
    if (checkObjective === true) {
      API.Department.setDepartmentObjectiveStatus({
        data: {
          objectiveId: iconData?._id,
          is_objective_edited: false,
        },
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            // setIconData(false);
            // setCheckObjective(undefined);
            getSingledepartmentById();
            toast.success(response.data.message);
            const closeButton = document.querySelector(
              "#check-objective-close"
            );
            if (closeButton) {
              closeButton.click();
            }
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (checkObjective === false) {
      API.Department.getDeptObjListByOrg({
        data: {
          objectiveId: iconData?._id,
          // is_objective_edited: false,
        },
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            console.log(response);
            setSingleObjectiveData(response?.data?.data);
            // setCheckObjective(undefined);
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (iconData && checkObjective !== undefined) {
      handleEditObjectiveSubmission();
    }
  }, [iconData]);

  useEffect(() => {
    if (onChangeObjectiveId) {
      fetchKeyResultData(onChangeObjectiveId);
    } else if (singleObjectiveData?.length > 0) {
      fetchKeyResultData(singleObjectiveData[0]._id);
      setOnChangeObjectiveId(singleObjectiveData[0]._id);
    }
  }, [onChangeObjectiveId, singleObjectiveData]);

  const handleDepartmentChange = (e) => {
    const selectedObjectiveId = e.target.value;
    setOnChangeObjectiveId(selectedObjectiveId);
    API.Department.KeyResultListByDept({
      data: { deptObjectiveId: selectedObjectiveId },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setKeyResultData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchKeyResultData = (deptObjectiveId) => {
    API.Department.KeyResultListByDept({
      data: { deptObjectiveId: deptObjectiveId },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setKeyResultData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [deleteID, setObjDeleteID] = useState(false);

  const handleOpenModal = (id) => {
    setObjDeleteID(id);
    const modalElement = document.getElementById("taskDeleteModal");
    modalElement.classList.add("show");
    modalElement.style.display = "block";
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    const modalElement = document.getElementById("taskDeleteModal");
    modalElement.classList.remove("show");
    modalElement.style.display = "none";
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
  };

  const deleteKeyResult = () => {
    API.Auth.DeleteDepartmentKeyResult({
      data: { _id: deleteID },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data);
          toast.success(response.data.message);

          API.Department.KeyResultListByDept({
            data: { deptObjectiveId: onChangeObjectiveId },
          })
            .then((response) => {
              if (response?.data?.statusCode === 200) {
                setKeyResultData(response?.data?.data);
                handleCloseModal();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (addNewObjName) {
      setNewObjNameError("");
    }
    if (addNewObjDesc) {
      setNewObjDescError("");
    }
  }, [addNewObjName, addNewObjDesc]);

  const handleNewObjSubmit = () => {
    if (!addNewObjName || !addNewObjDesc) {
      setNewObjNameError("Please enter name");
      setNewObjDescError("Please enter description");
    }
    if (addNewObjective) {
      API.Department.createDepartment({
        data: {
          dept_objective_name: addNewObjName?.trim(),
          dept_objective_description: addNewObjDesc?.trim(),
          objective_id: iconData?._id,
        },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            API.Department.getDeptObjListByOrg({
              data: {
                objectiveId: iconData?._id,
              },
            })
              .then((response) => {
                if (response.data.statusCode === 200) {
                  setSingleObjectiveData(response?.data?.data);
                  setAddNewObjective(false);
                  EditObjFormik.resetForm();
                  setAddNewObjName("");
                  setAddNewObjDesc("");
                } else {
                  toast.error(response?.data?.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      API.Department.updateMultipleDepartmentObj({
        data: {
          department_objective_array: editedObjectives,
        },
      })
        .then((response) => {
          if (response?.data?.result === true) {
            toast.success("Department objectives updated successfully!");
            API.Department.getDeptObjListByOrg({
              data: {
                objectiveId: iconData?._id,
              },
            })
              .then((response) => {
                if (response.data.statusCode === 200) {
                  setSingleObjectiveData(response?.data?.data);
                  setAddNewObjective(false);
                  EditObjFormik.resetForm();
                  const taskClick =
                    document.getElementById("pills-profile-tab");
                  taskClick?.click();
                  setAddNewObjName("");
                  setAddNewObjDesc("");
                } else {
                  toast.error(response?.data?.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleNewTaskSubmission = () => {
    if (addNewTask) {
      API.Department.createTaskDepartment({
        data: {
          department_objective_id: onChangeObjectiveId,
          key_result: addNewKeyResult,
          objective_initiative_task: addNewTaskInitiative,
        },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            fetchKeyResultData(onChangeObjectiveId);
            setAddNewTask(false);
            setAddNewKeyResult("");
            setAddNewTaskInitiative("");
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      API.Department.updateMultipleKeyResult({
        data: {
          key_result_array: editedKeyResults,
        },
      })
        .then((response) => {
          if (response?.data?.result === true) {
            console.log("🤷‍♀️🤷‍♀️", response);
            fetchKeyResultData(onChangeObjectiveId);
            toast.success("Key result updated successfully!");
            const closeMainModal = document.getElementById(
              "exampleModalToggle2"
            );
            closeMainModal?.click();
            setAddNewTask(false);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading py-2">
            <div className="col-12 d-sm-flex justify-content-between">
              <div className="d-flex align-items-center ps-3 mb-3 mb-sm-0">
                <NavLink className="" to="/department">
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">{postData?.DeptName}</h3>
              </div>
              <div className="d-flex align-items-center me-2">
                <img
                  src={
                    postData?.DeptImage
                      ? `${process.env.REACT_APP_BASE_URL}/images/${postData?.DeptImage}`
                      : departmentImg
                  }
                  className="me-3  Organization-img"
                />{" "}
                <div>
                  <small className="lightGrey mb-0">
                    {" "}
                    {postData?.DeptName} Owner
                  </small>
                  <h5 className="textDarkBlack mb-1 fw-bold">
                    {postData.fullname ? postData.fullname : "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-12">
              <h3 className="textDarkBlack mb-1 fw-bold">
                Organization Objective
              </h3>
              <small className="textDarkGrey">
                Below are the list of your organizational objectives that this
                department is working towards
              </small>
              <div
                className="accordion accordion-flush mt-2"
                id="accordionFlushExample1"
              >
                {departmentData &&
                  departmentData?.map((item, index) => {
                    return (
                      <div className="accordion-item mb-3 border-0" key={index}>
                        <h2
                          className="accordion-header position-relative "
                          id={`flush-heading${index + 1}`}
                        >
                          {item?.is_objective_edited === true ? (
                            <span
                              data-bs-target="#alert-model"
                              data-bs-toggle="modal"
                              className="position-absolute alert-icon"
                              onClick={() => {
                                setIconData(item), setCheckObjective(undefined);
                              }}
                            >
                              <img
                                src={alertImg}
                                width={50}
                                height={50}
                                alt="Alert"
                              />
                            </span>
                          ) : (
                            <span
                              data-bs-target="#exampleModalToggle2"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              className="position-absolute alert-icon"
                              onClick={() => {
                                setIconData(item), setCheckObjective(false);
                              }}
                            >
                              <img src={editIcon} alt="editIcon" />
                            </span>
                          )}
                          <button
                            className={` accordion-button progressCard p-3 collapsed`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse${index + 1}`}
                          >
                            <div
                              className={` row w-100 d-flex align-items-center`}
                            >
                              <div className="col-lg-6 alertIcon-padding">
                                <h4 className="textBlueLight">
                                  #.{item?.objective_name}
                                </h4>
                                <p className="textDarkBlack mb-0">
                                  {item?.description}
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <div className="row align-items-center">
                                  <div className="col-lg-1 mt-2">
                                    <h3 className="textBlueLight fw-bold">
                                      {item?.org_percentage_calculation
                                        ? Math.round(
                                          item?.org_percentage_calculation *
                                          100
                                        ) / 100
                                        : 0}
                                      %
                                    </h3>
                                  </div>
                                  <div className="col-xl-10 col-lg-11 ps-5">
                                    <div className="progress">
                                      <div
                                        className={`progress-bar`}
                                        role="progressbar"
                                        style={{
                                          width: `${item?.org_percentage_calculation}%`,
                                        }}
                                        aria-valuenow={
                                          item?.org_percentage_calculation
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse${index + 1}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`flush-heading${index + 1}`}
                          data-bs-parent="#accordionFlushExample1"
                        // key={index}
                        >
                          <div className="accordion-body px-0">
                            {/* {item?.objectiveList?.length > 0 ? ( */}
                            <div className="position-relative">
                              {item?.objectiveList
                                .filter(
                                  (objective) => objective.is_active === true
                                )
                                .map((objective, objIndex) => (
                                  <>
                                    <NavLink
                                      className="text-decoration-none"
                                      key={objIndex}
                                      to={`/timely-delivery/${objective?._id}/${type}`}
                                      onClick={() =>
                                        NeededInfo(
                                          item?.objective_name,
                                          objective?.dept_objective_name,
                                          objective?.dept_objective_description,
                                          // objective?.percentage_calculation,
                                          index
                                        )
                                      }
                                    >
                                      <div className="row w-100 d-flex align-items-center justify-content-cente mb-2">
                                        <div className="col-lg-1 d-flex justify-content-center">
                                          <div className="organizationNo">
                                            {objIndex + 1}
                                          </div>
                                          <div className="vLine"></div>
                                        </div>
                                        <div className="col-lg-11 p-3 bg-light-blue ">
                                          <div className="row align-items-center text-lg-start text-center">
                                            <div className="col-lg-10">
                                              <h4 className="textBlueLight">
                                                {objective?.dept_objective_name}
                                              </h4>
                                              <p className="textDarkBlack mb-0">
                                                {
                                                  objective?.dept_objective_description
                                                }
                                              </p>
                                            </div>
                                            <div className="col-lg-2 d-flex justify-content-around align-items-center">
                                              <h3 className="textBlueLight fw-bold mb-0">
                                                {Math.round(
                                                  objective?.percentage_calculation *
                                                  100
                                                ) / 100}
                                                %
                                              </h3>
                                              <i className="fa fa-solid fa-chevron-right textBlueLight"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </>
                                ))}
                              {type === "user" ? (
                                <div className="row w-100 d-flex align-items-center justify-content-center mt-3">
                                  <div className="col-lg-1 d-flex justify-content-center">
                                    <div className="organizationNo addPlus">
                                      +
                                    </div>
                                    <div className="vLine"></div>
                                  </div>
                                  <div className="col-lg-11 p-3 ps-0 ">
                                    <button
                                      type="button"
                                      className="btn sign-up-button-color text-white px-5"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#staticBackdrop${item?._id}`}
                                      id={`AddDepartment${item?._id}`}
                                      onClick={() =>
                                        handleButtonClick(item?._id)
                                      }
                                    >
                                      Add Department Objective
                                    </button>
                                    <div
                                      className={`modal fade`} // Add 'show' class when modal is open
                                      id={`staticBackdrop${item._id}`}
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabIndex={-1}
                                      aria-labelledby={`staticBackdropLabel${item._id}`}
                                      aria-hidden="true" // Hide modal when state is false
                                    // style={{ zIndex: "99999" }}
                                    >
                                      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable bg-transparent">
                                        <div className="modal-content">
                                          <div className="modal-header border-0">
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              onClick={() => formik.resetForm()}
                                            />
                                          </div>
                                          <div className="modal-body px-5">
                                            <h3 className="textDarkBlack mb-4">
                                              Add Objective
                                            </h3>
                                            <p className="textSecondary">
                                              Ensure that the objective aligns
                                              with your organization&apos;s
                                              long-term goals and strategy. This
                                              will make it less likely to be
                                              changed in the future
                                            </p>

                                            <form>
                                              <div className="mb-4">
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    name="dept_objective_name"
                                                    value={
                                                      formik.values
                                                        .dept_objective_name
                                                    }
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    // maxLength="30"
                                                    className="form-control mt-2"
                                                    placeholder="Enter department objective"
                                                    id="exampleInputfirstname"
                                                  />
                                                </div>
                                                {formik.errors
                                                  .dept_objective_name &&
                                                  formik.touched
                                                    .dept_objective_name ? (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors
                                                        .dept_objective_name
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className="mb-4">
                                                <div className="form-group">
                                                  <textarea
                                                    className="form-control"
                                                    name="dept_objective_description"
                                                    value={
                                                      formik.values
                                                        .dept_objective_description
                                                    }
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    placeholder="Enter a small description of the objective..."
                                                    id="floatingTextarea"
                                                    rows="4"
                                                  // maxLength="100"
                                                  ></textarea>
                                                </div>
                                                {formik.errors
                                                  .dept_objective_description &&
                                                  formik.touched
                                                    .dept_objective_description ? (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors
                                                        .dept_objective_description
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>

                                              <div className="">
                                                <button
                                                  type="submit"
                                                  className="btn font-size-14  sign-up-button-color text-white px-5"
                                                  onClick={formik.handleSubmit}
                                                >
                                                  Publish
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="alert-model"
        aria-hidden="true"
        aria-labelledby="alert-model"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable bg-transparent">
          <div className="modal-content position-relative ">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close position-absolute custom-close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="check-objective-close"
                onClick={() => {
                  formik.resetForm();
                  setCheckObjective(undefined);
                }}
              />
            </div>
            <div className="modal-body pt-0 pb-0 ps-3 pe-3">
              <h3 className="textDarkBlack mb-3 fw-semibold">
                Check Objective
              </h3>
              <div className="mb-3">
                <span className="textSecondary textBlueLight1">
                  {iconData?.objective_name}
                </span>
              </div>
              <p className="textSecondary textDarkBlack">
                {iconData?.description}
              </p>
              <h5 className="textDarkBlack mb-3 fw-semibold">
                Does your task aligns with the updated Objectives
              </h5>

              <div className="form-check form-check-inline ms-2 mb-0">
                <input
                  className="form-check-input custom-checkbox"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadioOptionsYes"
                  value={true}
                  checked={checkObjective === true}
                  onChange={() => {
                    setCheckObjective(true);
                  }}
                />
                <label
                  htmlFor="inlineRadioOptionsYes"
                  className="form-check-label"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline mb-0 ms-5">
                <input
                  className="form-check-input custom-checkbox"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadioOptionsNo"
                  value={false}
                  checked={checkObjective === false}
                  onChange={() => {
                    setCheckObjective(false);
                  }}
                />
                <label
                  htmlFor="inlineRadioOptionsNo"
                  className="form-check-label"
                >
                  No
                </label>
              </div>

              {/* <NavLink
                to="/objective"
                type="submit"
                className="btn font-size-14  sign-up-button-color text-white px-5 mt-4 d-block w-50"
              >
                <span className="">Confirm</span>
              </NavLink> */}

              {checkObjective === true ? (
                <button
                  className="btn font-size-14 edit-objective-confirm-button text-white px-5 mt-4 d-block w-50"
                  // data-bs-target="#exampleModalToggle2"
                  // data-bs-toggle="modal"
                  // data-bs-dismiss="modal"
                  disabled={checkObjective === undefined}
                  onClick={() => {
                    handleEditObjectiveSubmission();
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button
                  className="btn font-size-14 edit-objective-confirm-button text-white px-5 mt-4 d-block w-50"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  disabled={checkObjective === undefined}
                  onClick={() => {
                    handleEditObjectiveSubmission();
                  }}
                >
                  Confirm
                </button>
              )}
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade onbjectTabsModel"
        id="exampleModalToggle2"
        aria-hidden="true"
        // aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content position-relative">
            {/* <div className="modal-header">
        <h5 className="modal-title" id="exampleModalToggleLabel2">Modal 2</h5>
       
      </div> */}
            <div className="modal-body">
              <button
                type="button"
                className="btn-close position-absolute custom-close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  formik.resetForm();
                  setAddNewObjective(false);
                }}
              ></button>
              {/* <div className="back container mb-3">Update your Objective along with the task</div> */}
              <ul
                className="nav nav-pills mb-3 container btn-group max-width-634 p-0 objective-tabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link active w-100 h-100 rounded-pill rounded-end`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={() => {
                      setOnChangeObjectiveId(undefined);
                      setAddNewObjective(false);
                    }}
                  >
                    Objective
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 h-100 rounded-pill rounded-start`}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    onClick={() => {
                      setAddNewTask(false);
                    }}
                  >
                    Task
                  </button>
                </li>
              </ul>
              <div className="tab-content container p-0" id="pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="col-md-8 mx-auto mb-2 mt-4 lightGrey">
                    Change your objective and its respective task according to
                    the updated organizational objective
                  </p>
                  <div className="col col-md-8 mx-auto p-3 bg-blue-fait rounded-2 mb-5">
                    <p className="mb-2 fw-semobold textBlueLight1">
                      {iconData?.objective_name}
                    </p>
                    <p className="fw-regular textDarkblack mb-0">
                      {iconData?.description}
                    </p>
                  </div>

                  {/* Task modal */}
                  <div
                    id="key-result-carousel"
                    className="carousel slide col-md-12 mx-auto"
                    data-bs-ride="carousel"
                    data-interval="false"
                  >
                    {addNewTask ? (
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="col-md-7">
                            <p className="mb-1 mt-3 textDarkGrey col-md-7">
                              Task {keyResultData?.length + 1}
                            </p>
                            <select
                              className="form-select objective-select rounded-3 col-md-7"
                              aria-label="Default select example"
                              // id="definedepartment"
                              name="dept_objective_id"
                              onChange={handleDepartmentChange}
                              value={onChangeObjectiveId}
                            >
                              {singleObjectiveData &&
                                singleObjectiveData?.map((item) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.dept_objective_name.length > 20
                                      ? item?.dept_objective_name.slice(0, 30) +
                                      "..."
                                      : item?.dept_objective_name}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col p-0">
                            <p className="mb-1 mt-3 textDarkGrey">
                              Objective Initiative
                            </p>
                            <div className="p-3 bg-gray rounded-2 mb-3">
                              <input
                                type="text"
                                className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                                value={addNewTaskInitiative}
                                onChange={(e) => {
                                  setAddNewTaskInitiative(e.target.value);
                                }}
                                placeholder="Enter Initiative towards the objective"
                                maxLength={100}
                              />
                            </div>
                          </div>

                          <div className="col p-0">
                            <p className="mb-1 mt-3 textDarkGrey">Key Result</p>
                            <div className="p-3 bg-gray rounded-2 mb-3">
                              <input
                                type="text"
                                className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                                value={addNewKeyResult}
                                onChange={(e) => {
                                  setAddNewKeyResult(e.target.value);
                                }}
                                placeholder="Enter Key Result"
                                maxLength={500}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="carousel-inner">
                        {keyResultData?.length > 0 ? (
                          keyResultData?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  index === 0
                                    ? "carousel-item active"
                                    : "carousel-item"
                                }
                              >
                                {/* <button
                                  type="button"
                                  className="btn btn-primary float-right delete-custom-btn"
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    deleteKeyResult(item?._id);
                                  }}
                                > */}
                                <button
                                  type="button"
                                  className="btn btn-primary float-right delete-custom-btn d-flex"
                                  style={{ float: "right" }}
                                  onClick={() => handleOpenModal(item?._id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                                  </svg>
                                </button>
                                <div className="col-md-7">
                                  <p className="mb-1 mt-3 textDarkGrey col-md-7">
                                    Task {index + 1}
                                  </p>
                                  <select
                                    className="form-select objective-select rounded-3 col-md-7"
                                    aria-label="Default select example"
                                    // id="definedepartment"
                                    name="dept_objective_id"
                                    onChange={handleDepartmentChange}
                                    value={onChangeObjectiveId}
                                  >
                                    {singleObjectiveData &&
                                      singleObjectiveData?.map((item) => (
                                        <option
                                          key={item?._id}
                                          value={item?._id}
                                        >
                                          {item?.dept_objective_name.length > 20
                                            ? item?.dept_objective_name.slice(
                                              0,
                                              30
                                            ) + "..."
                                            : item?.dept_objective_name}{" "}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col p-0">
                                  <p className="mb-1 mt-3 textDarkGrey">
                                    Objective Initiative
                                  </p>
                                  <div className="p-3 bg-gray rounded-2 mb-3">
                                    <input
                                      type="text"
                                      className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                                      placeholder="Enter Initiative towards the objective"
                                      maxLength={100}
                                      value={
                                        editedKeyResults[index]
                                          ?.objective_initiative_task
                                      }
                                      onChange={(event) => {
                                        const updatedObjectives = [
                                          ...editedKeyResults,
                                        ];
                                        updatedObjectives[
                                          index
                                        ].objective_initiative_task =
                                          event.target.value;
                                        setEditedKeyResults(updatedObjectives);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col p-0">
                                  <p className="mb-1 mt-3 textDarkGrey">
                                    Key Result
                                  </p>
                                  <div className="p-3 bg-gray rounded-2 mb-3">
                                    <input
                                      type="text"
                                      placeholder="Enter Key Result"
                                      className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                                      maxLength={500}
                                      value={
                                        editedKeyResults[index]?.key_result
                                      }
                                      onChange={(event) => {
                                        const updatedObjectives = [
                                          ...editedKeyResults,
                                        ];
                                        updatedObjectives[index].key_result =
                                          event.target.value;
                                        setEditedKeyResults(updatedObjectives);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            <div className="col-md-7">
                              <p className="mb-1 mt-3 textDarkGrey col-md-7">
                                Task
                              </p>
                              <select
                                className="form-select objective-select rounded-3 col-md-7"
                                aria-label="Default select example"
                                // id="definedepartment"
                                name="dept_objective_id"
                                onChange={handleDepartmentChange}
                                value={onChangeObjectiveId}
                              >
                                {singleObjectiveData &&
                                  singleObjectiveData?.map((item) => (
                                    <option key={item?._id} value={item?._id}>
                                      {item?.dept_objective_name.length > 20
                                        ? item?.dept_objective_name.slice(
                                          0,
                                          30
                                        ) + "..."
                                        : item?.dept_objective_name}{" "}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <h4
                              className="d-flex justify-content-center"
                              style={{
                                height: "100%",
                                margin: "20px 0px 20px 0px",
                              }}
                            >
                              No tasks found!
                            </h4>
                          </>
                        )}
                      </div>
                    )}

                    <div className="col-12 btn-groups d-flex justify-content-between mt-2">
                      <button
                        type="button"
                        className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255 pt-2 pb-2 blue-btn"
                        onClick={() => {
                          setAddNewTask(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"></path>
                        </svg>
                        Add New
                      </button>
                      {addNewTask ? (
                        <button
                          type="button"
                          className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255  pt-2 pb-2"
                          onClick={() => {
                            handleNewTaskSubmission();
                          }}
                          disabled={!addNewKeyResult || !addNewTaskInitiative}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255  pt-2 pb-2"
                          onClick={() => {
                            handleNewTaskSubmission();
                          }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                    {!addNewTask && (
                      <button
                        className="carousel-control-prev objective-prev-btn "
                        type="button"
                        data-bs-target="#key-result-carousel"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon "
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                    )}
                    {!addNewTask && (
                      <button
                        className="carousel-control-next objective-next-btn"
                        type="button"
                        data-bs-target="#key-result-carousel"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    )}
                  </div>

                  <div
                    className="modal fade"
                    id="taskDeleteModal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="taskDeleteModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered justify-content-center">
                      <div
                        className="modal-content p-5 border"
                        style={{ width: "50%" }}
                      >
                        <div className="modal-body text-start text-center">
                          <h2 className="textGrey">
                            Are you sure you want to delete this task?
                          </h2>
                          <div className="d-flex justify-content-center mt-3">
                            <button
                              type="button"
                              className="btn btnOrganization w-auto px-5 mt-3 me-3"
                              // data-bs-dismiss="modal"
                              onClick={() => handleCloseModal()}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn sign-up-button-color text-white px-5 mt-3"
                              onClick={() => deleteKeyResult()}
                            >
                              Delete
                            </button>
                          </div>
                          {/* <br />
              <p><span>Note : </span> Deleting this objective will also remove all associated tasks.</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Task modal */}
                </div>

                <DepartmentObjective
                  iconData={iconData}
                  addNewObjective={addNewObjective}
                  singleObjectiveData={singleObjectiveData}
                  addNewObjName={addNewObjName}
                  setAddNewObjName={setAddNewObjName}
                  newObjNameError={newObjNameError}
                  addNewObjDesc={addNewObjDesc}
                  setAddNewObjDesc={setAddNewObjDesc}
                  newObjDescError={newObjDescError}
                  editedObjectives={editedObjectives}
                  setEditedObjectives={setEditedObjectives}
                  setAddNewObjective={setAddNewObjective}
                  setNewObjNameError={setNewObjNameError}
                  setNewObjDescError={setNewObjDescError}
                  setSingleObjectiveData={setSingleObjectiveData}
                  handleNewObjSubmit={handleNewObjSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
