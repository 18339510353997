/* eslint-disable */
// eslint-disable-next-line react/no-deprecated
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import BarChart from "./BarChart";
import { useSelector } from "react-redux";
import API from "../../Api/Api";
import Carousel from "react-grid-carousel";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [isOwlCarouselVisible, setIsOwlCarouselVisible] = useState(false);
  const [DashboardData, setdashboardData] = useState(null);
  const [dropdownData, setDropdownData] = useState(null);
  const { sDate, EDate } = useSelector((state) => state.date);
  const [departmentObjectivesData, setDepartmentObjectivesData] =
    useState(null);
  const [onChangeDepartmentId, setOnChangeDepartmentId] = useState();
  const UsersData = JSON.parse(localStorage.getItem("DepartmentUser"));
  const navigate = useNavigate();

  const [dropdownList, setDropdownList] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState([]);
  const [selectDepartmentIndex, setSelectDepartmentIndex] = useState();
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);

  const getAllDashboardData = async () => {
    if (sDate && EDate) {
      await API.Dashboard.getDashboardData({ sDate, EDate })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setdashboardData(response.data.data);
            setIsOwlCarouselVisible(true);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            navigate("/");
          }
        });
    }
  };

  const getDropdownData = () => {
    API.Department.getDepartmentListWithProgress()
      .then((response) => {
        if (response.data.statusCode === 200) {
          setDropdownData(response.data.data);
          setDropdownList(response.data.data?.departmentList);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getAllDashboardData();
    getDropdownData();
  }, [sDate, EDate]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      $(".owl-carousel").owlCarousel();
      setInitialized(true);
    }
  }, [initialized]);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      overlay={
        <Tooltip id={id}>
          <div
            className="tooltip-inner-custom"
            style={{
              backgroundColor: "#f7f9ff",
              color: "#000",
              padding: "10px",
              borderColor: "#f7f9ff",
            }}
          >
            {title}
          </div>
        </Tooltip>
      }
      trigger={title.length > 30 ? ["hover", "focus"] : []}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
  const TotalOKRper =
    (DashboardData?.org_objective_list?.overall_obj_score * 100) / 100;

  useEffect(() => {
    if (onChangeDepartmentId) {
      fetchDepartmentData(onChangeDepartmentId);
    } else if (dropdownData?.departmentList?.length > 0) {
      fetchDepartmentData(dropdownData?.departmentList[0]?._id);
    }
  }, [onChangeDepartmentId, dropdownData?.departmentList]);

  const fetchDepartmentData = async (departmentId) => {
    await API.Department.getDepartmentObjectiveListForDashboard({
      data: { departmentId },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setDepartmentObjectivesData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDepartmentChange2 = (e, index) => {
    setSelectDepartmentIndex(index);
    setSelectedObjectiveId(e.target.value);
    setCurrentDepartment(dropdownList[index]);
    setSelectDepartmentIndex(index);
    const selectedDepartmentId = e.target.value;
    setOnChangeDepartmentId(selectedDepartmentId);
    API.Department.getDepartmentObjectiveListForDashboard({
      data: { departmentId: selectedDepartmentId },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setDepartmentObjectivesData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!selectedObjectiveId) {
      setSelectedObjectiveId(dropdownList[0]?._id);
      setOnChangeDepartmentId(dropdownList[0]?._id);
      setCurrentDepartment(dropdownList[0]);
      setSelectDepartmentIndex(0);
    }
  }, [dropdownList, selectDepartmentIndex, departmentObjectivesData]);

  const TooltipCustom = (field) => {
    return field && field.length > 35 ? (
      <div className="height-30 textBreak" data-toggle="tooltip" title={field} c>
        {field.substring(0, 35) + "..."}
        {/* ... */}
      </div>
    ) : (
      <div>{field}</div>
    );
  };

  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">
                  Welcome to {UsersData?.dept_name} Dashboard
                </h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h4 className="">Organizational Objective</h4>
              </div>
            </div>
            <div className="row bg-light-blue p-3">
              <div className="col-xl-2 col-lg-3 d-flex justify-content-center">
                <div>
                  <p className="textBlueLight1 fw-bold">Overall OKR Growth</p>
                  <>
                    <div
                      style={{ width: "150px", height: "150px" }}
                      className="m-3"
                    >
                      <CircularProgressbar
                        // key={animationKey}
                        value={
                          DashboardData?.org_objective_list?.overall_obj_score
                        }
                        text={`${Math.round(
                          DashboardData?.org_objective_list
                            ?.overall_obj_score === undefined
                            ? 0
                            : DashboardData?.org_objective_list
                              ?.overall_obj_score * 100
                        ) / 100
                          }%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          rotation: 0.0,
                          strokeLinecap: "butt",
                          textSize: "20px",
                          className: "bold-text",
                          pathTransitionDuration: 0.5,
                          pathColor: `${TotalOKRper < 30
                            ? "red"
                            : TotalOKRper > 30 && TotalOKRper < 60
                              ? "#ECB159"
                              : "green"
                            }`,
                          textColor: `${TotalOKRper < 30
                            ? "red"
                            : TotalOKRper > 30 && TotalOKRper < 60
                              ? "#ECB159"
                              : "green"
                            }`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="col-lg-9 col-12 ms-3">
                {/* <div className="row"> */}
                {isOwlCarouselVisible && (
                  <OwlCarousel
                    items={4}
                    slideBy={4}
                    className="owl-theme ms-5 dashboardSlider"
                    dots={false}
                    nav
                    margin={8}
                    responsive={{
                      0: { items: 1 },
                      768: { items: 3 },
                      992: { items: 4 },
                    }}
                  >
                    {DashboardData &&
                      DashboardData?.org_objective_list &&
                      DashboardData?.org_objective_list?.result.map(
                        (item, index) => {
                          return (
                            <div
                              className="col-xl-3 col-md-6 d-flex justify-content-center ps-5"
                              key={index}
                            >
                              <div
                                className="p-3 progressCard mb-3"
                                style={{
                                  height: "200px",
                                  display: "flex",
                                  wordBreak: "break-word",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  title={item?.objective_name}
                                  id="t-1"
                                  className="textBlueLight1 fw-bold mb-0 height-30 textBreak"
                                >
                                  {TooltipCustom(item?.objective_name)}
                                </span>

                                <div className="d-flex justify-content-center align-items-center mx-4 my-4">
                                  <div
                                    style={{ width: "100px", height: "100px" }}
                                  >
                                    <CircularProgressbar
                                      value={item?.org_percentage_calculation}
                                      text={`${Math.round(
                                        item?.org_percentage_calculation * 100
                                      ) / 100
                                        }%`}
                                      strokeWidth={10}
                                      styles={buildStyles({
                                        rotation: 0.0,
                                        strokeLinecap: "butt",
                                        textSize: "20px",
                                        className: "bold-text ",
                                        pathTransitionDuration: 0.5,
                                        pathColor: `
                                        ${item?.org_percentage_calculation < 30
                                            ? "red"
                                            : item?.org_percentage_calculation >
                                              30 &&
                                              item?.org_percentage_calculation <
                                              60
                                              ? "#ECB159"
                                              : "green"
                                          }
                                       `,
                                        textColor: `${item?.org_percentage_calculation < 30
                                          ? "red"
                                          : item?.org_percentage_calculation >
                                            30 &&
                                            item?.org_percentage_calculation <
                                            60
                                            ? "#ECB159"
                                            : "green"
                                          }
                                       `,
                                        trailColor: "#d6d6d6",
                                        backgroundColor: "#3e98c7",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </OwlCarousel>
                )}
                {/* </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-5">
                <h4 className="mt-4">Organization Objective Progress</h4>
                <BarChart
                  data={DashboardData && DashboardData?.org_objective_progress}
                />
              </div>
              <div className="col-xl-7">
                <div className="row justify-content-center">
                  <div className="col-lg-11">
                    <h4 className="mt-4">Departmental OKR Growth</h4>
                    <div>
                      {isOwlCarouselVisible && (
                        <OwlCarousel
                          items={1}
                          className="owl-theme"
                          dots={false}
                          nav
                          margin={8}
                        >
                          <div>
                            <div className="row align-items-center departmentHeading mt-2">
                              <div className="col-6 d-flex justify-content-between align-items-center">
                                <div class="form-group d-flex align-items-center">
                                  <select
                                    class="form-select form-control bg-white py-2 me-3"
                                    style={{ width: "230px" }}
                                    aria-label="Default select example"
                                    id="definedepartment"
                                    name="departmentId"
                                    onChange={(e) =>
                                      handleDepartmentChange2(
                                        e,
                                        e.target.selectedIndex
                                      )
                                    }
                                    value={onChangeDepartmentId}
                                  >
                                    {dropdownList.length <= 0 ? (
                                      <>
                                        <option value={dropdownData.user?._id}>
                                          {dropdownData.user?.dept_name.length >
                                            20
                                            ? dropdownData.user?.dept_name.slice(
                                              0,
                                              20
                                            ) + "..."
                                            : dropdownData.user?.dept_name}{" "}
                                        </option>
                                      </>
                                    ) : (
                                      <>
                                        {dropdownList &&
                                          dropdownList?.map((item) => {
                                            return (
                                              <option
                                                key={item?._id}
                                                value={item?._id}
                                              >
                                                {item?.dept_name.length > 20
                                                  ? item?.dept_name.slice(
                                                    0,
                                                    20
                                                  ) + "..."
                                                  : item?.dept_name}{" "}
                                              </option>
                                            );
                                          })}
                                      </>
                                    )}
                                  </select>

                                  <h3 className="textBlueLight1 fw-bold mb-0">
                                    {currentDepartment?.dept_growth
                                      ? Math.round(
                                        currentDepartment?.dept_growth * 100
                                      ) / 100
                                      : 0}
                                    %
                                  </h3>
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end align-items-center">
                                <div>
                                  <p className="lightGrey mb-0">
                                    {currentDepartment?.fullName
                                      ? currentDepartment?.fullName
                                      : "-"}
                                  </p>
                                </div>
                                <img
                                  src={
                                    currentDepartment?.profileImageUrl
                                      ? `${process.env.REACT_APP_BASE_URL}/images/${currentDepartment?.profileImageUrl}`
                                      : departmentImg
                                  }
                                  className="ms-3 swiper-slide-img"
                                />
                              </div>
                            </div>
                            {departmentObjectivesData?.length > 0 ? (
                              <div className="row departmentCards p-3">
                                {departmentObjectivesData?.length > 3 ? (
                                  <Carousel
                                    cols={3}
                                    rows={1}
                                    gap={10}
                                    loop
                                    showArrows={true}
                                  >
                                    {departmentObjectivesData?.map(
                                      (item, index) => (
                                        <Carousel.Item
                                          key={index}
                                          className="mx-3"
                                        >
                                          {/* <div className="col-md-3 col-sm-6"> */}
                                          <div
                                            className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                            style={{ height: "140px" }}
                                          >
                                            <div>
                                              <Link
                                                title={item?.objective_name}
                                                id="t-1"
                                                className="textBlueLight1 fw-bold mb-0"
                                                style={{
                                                  "max-width": '200px',
                                                  "max-height": '100px',
                                                }}
                                              >
                                                {TooltipCustom(item?.objective_name)}
                                              </Link>

                                              <h4 className="textBlueLight1 fw-bold mt-2">
                                                {Math.round(
                                                  item?.org_percentage_calculation *
                                                  100
                                                ) / 100}
                                                %
                                              </h4>
                                            </div>
                                          </div>
                                          {/* </div> */}
                                        </Carousel.Item>
                                      )
                                    )}
                                  </Carousel>
                                ) : (
                                  <>
                                    <div className="row">
                                      {departmentObjectivesData?.map(
                                        (item, index) => (
                                          <div className="col-3" key={index}>
                                            <div
                                              className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                              style={{
                                                height: "140px",
                                              }}
                                            >
                                              <div>
                                                <Link
                                                  title={item?.objective_name}
                                                  id="t-1"
                                                  className="textBlueLight1 fw-bold mb-0 height-30"
                                                >
                                                  {TooltipCustom(item?.objective_name)}
                                                </Link>

                                                <h4 className="textBlueLight1 fw-bold te-2">
                                                  {Math.round(
                                                    item?.org_percentage_calculation *
                                                    100
                                                  ) / 100}
                                                  %
                                                </h4>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <h5 className="py-2">
                                No other departments found!
                              </h5>
                            )}
                          </div>
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
