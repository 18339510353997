import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import "../../assets/css/style.css";
const CountdownComponent = ({
  desiredDay,
  desiredTime,
  timeSlotHours,
  endDay,
  endTime,
  setIsEditable,
}) => {
  console.log(
    "Props:",
    desiredDay,
    desiredTime,
    timeSlotHours,
    endDay,
    endTime
  );

  // const [countdownStarted, setCountdownStarted] = useState(true);
  const [getMiliseconds, setgetMiliseconds] = useState(0);
  // const [desiredHour, setDesiredHour] = useState(0);
  const [firstDayTimeInMs, setFirstDayTimeInMs] = useState(0);
  const [currentTimeInMs, setCurrentTimeInMs] = useState(0);
  const [lastDayTimeInMs, setLastDayTimeInMs] = useState(0);
  const [hasReloaded, setHasReloaded] = useState(0);

  useEffect(() => {
    if (
      getCurrentTime({
        desiredDay,
        desiredTime,
        timeSlotHours,
        endDay,
        endTime,
      })
    ) {
      // setCountdownStarted(true);
    } else {
      // setCountdownStarted(false); // Ensure countdownStarted is explicitly set to false if countdown shouldn't start
    }
  }, [desiredDay, desiredTime]);

  const getCurrentTime = (getData) => {
    let curr = new Date();
    console.log("curr", curr);
    let startDay = getData?.desiredDay;
    let endDay = getData?.endDay;
    let firstDayTime = getData?.desiredTime;
    let lastDayTime = getData?.endTime;
    // console.clear();
    console.log("lastDayTime", lastDayTime);
    console.log("getData", getData);

    let week = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const getDayOffset = (currentDay, targetDay) =>
      (targetDay - currentDay + 7) % 7;

    const parseTime = (timeString) => {
      let [hours, minutes, seconds] = timeString
        ? timeString.split(":").map(Number)
        : "";
      return { hours, minutes, seconds };
    };

    let currentDayIndex = curr.getDay();
    let startOffset = getDayOffset(currentDayIndex, week[startDay]);
    let endOffset = getDayOffset(currentDayIndex, week[endDay]);

    let firstDayDate = new Date(curr);
    firstDayDate.setDate(curr.getDate() + startOffset);

    console.log("startOffset", startOffset);
    let lastDayDate = new Date(curr);
    lastDayDate.setDate(curr.getDate() + endOffset);
    let {
      hours: lastDayHours,
      minutes: lastDayMinutes,
      seconds: lastDaySeconds,
    } = parseTime(lastDayTime);
    lastDayDate.setHours(lastDayHours, lastDayMinutes, lastDaySeconds);

    let timeDifferenceInMS = lastDayDate - firstDayDate;
    let {
      hours: firstDayHours,
      minutes: firstDayMinutes,
      seconds: firstDaySeconds,
    } = parseTime(firstDayTime);
    firstDayDate.setHours(firstDayHours, firstDayMinutes, firstDaySeconds);

    console.log("firstDayDate ---- ", firstDayDate);
    console.log("lastDayDate ----", lastDayDate);

    setFirstDayTimeInMs(firstDayDate.getTime());
    // console.log("first dat date in milliseconds", firstDayDate.getTime());
    // console.log("first date in millisecond", firstDayTimeInMs);

    setLastDayTimeInMs(lastDayDate.getTime());
    console.log("last dat date in milliseconds", lastDayTimeInMs);

    setCurrentTimeInMs(curr.getTime());
    // console.log("current date in milliseconds", currentTimeInMs);

    console.log("Time difference in hours:", timeDifferenceInMS);
    setgetMiliseconds(timeDifferenceInMS);

    let isStartTimer = false;

    return getData == "difference" ? timeDifferenceInMS : isStartTimer;
  };

  const handleCountdownComplete = () => {
    if (hasReloaded < 2) {
      // Reload only if not already reloaded twice
      window.location.reload();
      setHasReloaded(hasReloaded + 1); // Increment reload count
    }
  };

  const renderCountdown = () => {
    console.log("first date in millisecond", firstDayTimeInMs);
    console.log("current date in milliseconds", currentTimeInMs);
    console.log("last date in milliseconds", lastDayTimeInMs);
    if (
      currentTimeInMs >= firstDayTimeInMs &&
      getMiliseconds &&
      currentTimeInMs <= lastDayTimeInMs
    ) {
      setIsEditable(true);
      console.log("countdown started");
      return (
        <Countdown
          date={Date.now() + getMiliseconds}
          intervalDelay={0}
          precision={3}
          // onComplete={setIsEditable(false)}
          onComplete={handleCountdownComplete}
        />
      );
    } else {
      setIsEditable(false);
      return <p className="timer">00D :00H :00M : 00S</p>;
    }
  };
  return <div>{renderCountdown()}</div>;
};

CountdownComponent.propTypes = {
  desiredDay: PropTypes.string.isRequired,
  desiredTime: PropTypes.string.isRequired,
  timeSlotHours: PropTypes.number.isRequired,
  endDay: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  setIsEditable: PropTypes.bool.isRequired,
};

export default CountdownComponent;