/* eslint-disable */

import React, { useEffect, useState } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import timelyDelivery from "../../../assets/images/Icons/Pics/Department/SLA.png";
import API from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DefineDepartment = (props) => {
  const [subObjectiveList, setSubObjectiveList] = useState(null);
  const [KeyResultList, setKeyResultList] = useState(
    JSON.parse(localStorage.getItem("KeyResultListFromLocalStorage")) || null
  );
  const [Editid, setEditID] = useState(null);
  const [isEditing, setEditing] = useState(false);

  const [deleteId, setDeleteId] = useState();
  const DepatmentUser = JSON.parse(localStorage.getItem("DepartmentUser"));

  const navigate = useNavigate();
  console.log(KeyResultList);
  const validate = (values) => {
    console.log(values, "value");
    const spaceReg = /^\S.*\S$/;
    const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

    const errors = {};
    if (!values.objective_initiative_task) {
      errors.objective_initiative_task = "Please enter department task";
    } else if (!paragraphReg.test(values.objective_initiative_task)) {
      errors.objective_initiative_task = "Cannot start & end with a space";
    }
    if (!values.key_result) {
      errors.key_result = "Please enter department key result";
    } else if (!spaceReg.test(values.key_result)) {
      errors.key_result = "Cannot start & end with a space";
    } else {
      const selectedKeyResult = KeyResultList?.find(
        (item) =>
          item?.department_objective_id?.dept_objective_id ===
          formik.values.department_objective_id?.dept_objective_id
      );
      if (selectedKeyResult?.key_result === formik.values.key_result) {
        errors.key_result = `Entered key result already exists under ${selectedKeyResult.department_objective_id.dept_objective_name} objective`;
      }
    }
    if (!values.department_objective_id) {
      errors.department_objective_id = "Please select department objective";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      objective_initiative_task: "",
      key_result: "",
      department_objective_id: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      if (Editid) {
        handleUpdate();
      } else {
        createKeyResult();
      }
    },
    validate,
  });

  const getDepartmentList = () => {
    API.Auth.OrgObjDepartmentList({
      data: {
        departmentId: DepatmentUser?._id,
        organizationId: DepatmentUser?.organization_id,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setSubObjectiveList(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDepartmentList();
    getAllKeyResultList();
  }, []);

  const createKeyResult = () => {
    // API.Auth.RegisterAPI({
    //   data: {
    //     department_objective_id: formik.values.department_objective_id,
    //     key_result: formik.values.key_result,
    //     objective_initiative_task: formik.values.objective_initiative_task,
    //     flag: 4,
    //   },
    // }).then((response) => {
    //   if (response.data.statusCode === 200) {
    //     console.log(response);
    //     formik.resetForm();
    //     getAllKeyResultList();
    //   }else{
    //     toast.error(response?.data?.message);

    //   }
    // });
    const newObjective = {
      department_objective_id: formik.values.department_objective_id,
      key_result: formik.values.key_result,
      objective_initiative_task: formik.values.objective_initiative_task,
    };
    const newList = KeyResultList
      ? [...KeyResultList, newObjective]
      : [newObjective];
    setKeyResultList(newList);
    localStorage.setItem(
      "KeyResultListFromLocalStorage",
      JSON.stringify(newList)
    );

    formik.resetForm();
  };

  const getAllKeyResultList = () => {
    // API.Auth.GetALlKeyResultAPI()
    //   .then((response) => {
    //     if (response.data.statusCode === 200) {
    //       setKeyResultList(response.data.data);
    //       console.log(response);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleEdit = (id) => {
    const objectiveToEdit = KeyResultList?.find(
      (item) => item?.department_objective_id?.dept_objective_id === id
    );
    console.log(objectiveToEdit);
    if (objectiveToEdit) {
      formik.setValues({
        key_result: objectiveToEdit.key_result,
        objective_initiative_task: objectiveToEdit.objective_initiative_task,
        department_objective_id: objectiveToEdit.department_objective_id,
      });
      setEditing(true);
      setEditID(id);
    }
  };

  // Update api
  const handleUpdate = () => {
    //   const objectiveToEdit = KeyResultList.find((item) => item._id === Editid);

    //   if (
    //     objectiveToEdit.key_result === formik.values.key_result &&
    //     objectiveToEdit.objective_initiative_task === formik.values.objective_initiative_task &&
    //     objectiveToEdit.department_objective_id === formik.values.department_objective_id
    //   ) {
    //     formik.resetForm();
    //     setEditing(false);
    //     setEditID("");
    //   } else {
    //     API.Auth.UpdateKeyResultAPI({
    //       data: {
    //         _id: Editid,
    //         department_objective_id: formik.values.department_objective_id,
    //         key_result: formik.values.key_result,
    //         objective_initiative_task: formik.values.objective_initiative_task,
    //       },
    //     }).then((response) => {
    //       if (response?.data?.statusCode === 200) {
    //       console.log("response", response.data);
    //       getAllKeyResultList();
    //       formik.resetForm();
    //       setEditing(false);
    //       setEditID("");
    //       console.log("Objective Successfully Updated");
    //     } else {
    //       // Handle error or provide feedback to the user
    //       toast.error(response?.data?.message);
    //     }
    //     }).catch (error=>{
    //     console.log(error);
    //   })
    // }
    // Find the index of the objective to update
    const indexToUpdate = KeyResultList?.findIndex(
      (item) => item.department_objective_id?.dept_objective_id === Editid
    );

    if (indexToUpdate !== -1) {
      const updatedList = [...KeyResultList];
      updatedList[indexToUpdate] = {
        ...updatedList[indexToUpdate],
        department_objective_id: formik.values.department_objective_id,
        key_result: formik.values.key_result,
        objective_initiative_task: formik.values.objective_initiative_task,
      };
      setKeyResultList(updatedList);
      localStorage.setItem(
        "KeyResultListFromLocalStorage",
        JSON.stringify(updatedList)
      );

      formik.resetForm();
      setEditing(false);
      setEditID(null);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  // Deelete API
  const deleteDepartment = () => {
    // try {
    //   API.Auth.DeleteKeyResultAPI({
    //     data: { _id: deleteId },
    //   }).then((response) => {
    //     if(response.data.statusCode ===200){
    //     const newObjectiveId = response.data.data._id;
    //     const updatedArray = KeyResultList.filter(
    //       (item) => item._id !== newObjectiveId
    //     );
    //     setKeyResultList(updatedArray);
    //     document.querySelector("#DeleteDepartemnt").click();
    //     formik.resetForm();
    //     setEditing(false);
    //     setEditID("");
    //     console.log("Objective Successfully Deleted");
    //     }
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    const filteredList = KeyResultList.filter(
      (item) => item?.department_objective_id.dept_objective_id !== deleteId
    );

    setKeyResultList(filteredList);
    localStorage.setItem(
      "KeyResultListFromLocalStorage",
      JSON.stringify(filteredList)
    );

    document.querySelector("#DeleteDepartemnt").click();
    formik.resetForm();
    setEditing(false);
    setEditID(null);
  };

  const handleNextButton = () => {
    API.Auth.RegisterAPI({
      data: {
        key_results_array: KeyResultList?.map((item) => ({
          ...item,
          department_objective_id:
            item?.department_objective_id?.dept_objective_id,
          // objective_id: item?.department_objective_id?.objective_id,
        })),
        flag: 4,
        departmentId: DepatmentUser?._id,
        organizationId: DepatmentUser?.organization_id,
      },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200 && response?.data?.token) {
          props.handleNext();
          localStorage.setItem("DepartmentToken", response?.data?.token);
          localStorage.removeItem("KeyResultListFromLocalStorage");
          navigate(`/dashboard`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    formik.resetForm();
    setEditing(false);
    setEditID(null);
  };

  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Set Key Results</h2>
          <p className="textGrey">
            Make sure your key results are to the point{" "}
          </p>
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              <p className="textBlueLight">
                Select departmental objective and set your intiatives and key results
              </p>

              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      aria-label="Default select example"
                      id="definedepartment"
                      name="department_objective_id"
                      // value={formik.values.department_objective_id}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const selectedIndex = e.target.value;
                        if (selectedIndex !== "") {
                          const selectedOptionData =
                            subObjectiveList[selectedIndex];
                          const selectedObject = {
                            dept_objective_id: selectedOptionData?._id,
                            dept_objective_name:
                              selectedOptionData?.dept_objective_name,
                          };
                          formik.setFieldValue(
                            "department_objective_id",
                            selectedObject
                          );
                        } else {
                          formik.setFieldValue("department_objective_id", null);
                        }
                      }}
                      value={
                        formik.values.department_objective_id
                          ? subObjectiveList?.findIndex(
                            (item) =>
                              item._id ===
                              formik.values.department_objective_id
                                ?.dept_objective_id
                          )
                          : ""
                      }
                    >
                      <option selected>Select departmental objective</option>
                      {subObjectiveList &&
                        subObjectiveList.map((item, index) => (
                          <option key={item?._id} value={index}>
                            {item?.dept_objective_name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {formik.errors.department_objective_id &&
                    formik.touched.department_objective_id ? (
                    <div className="text-danger">
                      {formik.errors.department_objective_id}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="
                      Mention Initiative towards the objective"
                      id="floatingTextarea"
                      rows="4"
                      maxLength={100}
                      name="objective_initiative_task"
                      value={formik.values.objective_initiative_task}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                  {formik.errors.objective_initiative_task &&
                    formik.touched.objective_initiative_task ? (
                    <div className="text-danger">
                      {formik.errors.objective_initiative_task}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter Key Result"
                      id="exampleInputfirstname"
                      name="key_result"
                      value={formik.values.key_result}
                      onChange={formik.handleChange}
                      maxLength={500}
                    />
                  </div>
                  {formik.errors.key_result && formik.touched.key_result ? (
                    <div className="text-danger">
                      {formik.errors.key_result}
                    </div>
                  ) : null}
                </div>

                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Publish
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(event) => handleCancel(event)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">
                Here are the list of your initiatives and their key results
              </p>
              {KeyResultList?.length == 0 ? (
                <div className="row">
                  <div className="col-12 text-center py-5">
                    <img src={setObjective} />
                  </div>
                </div>
              ) : (
                <>
                  {KeyResultList &&
                    KeyResultList.map((item, index) => {
                      return (
                        <div
                          className="row d-flex align-items-center w-100 mb-1"
                          key={index}
                        >
                          <div className="col-10">
                            <div className="setObjectiveListBox p-3">
                              <div className="d-flex">
                                <img
                                  src={timelyDelivery}
                                  className="Width-20 me-2"
                                />
                                <div className="setObjectiveListBox">
                                  <p
                                    className="mb-1 textBlueLight1"
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    {
                                      item?.department_objective_id
                                        ?.dept_objective_name
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <i className="fa-solid fa-arrow-right me-2 iconBlue"></i>
                                <div className="setObjectiveListBox">
                                  <p
                                    className="mb-1 textLightGrey"
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    {item?.objective_initiative_task}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <i className="fa-solid fa-key me-2 iconBlue"></i>
                                <div className="setObjectiveListBox">
                                  <p
                                    className="mb-1 textGrey"
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    {item?.key_result}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-2">
                            <div className="d-flex">
                              <img
                                src={editIcon}
                                onClick={() => handleEdit(item?.department_objective_id?.dept_objective_id)}
                              />
                              <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item.department_objective_id?.dept_objective_id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                            </div>
                          </div> */}
                          <div className="col-2">
                            <div className="d-flex btn gap-2">
                              <img
                                width={36}
                                src={editIcon}
                                onClick={() =>
                                  handleEdit(
                                    item?.department_objective_id
                                      ?.dept_objective_id
                                  )
                                }
                              />
                              <button
                                type="button"
                                className="btn"
                                data-bs-toggle="modal"
                                id="DeleteDepartemnt"
                                data-bs-target="#staticBackdrop1"
                                onClick={() =>
                                  handleDelete(
                                    item.department_objective_id
                                      ?.dept_objective_id
                                  )
                                }
                              >
                                <img src={deleteIcon} width={36} />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              <button
                className={`btn ${!KeyResultList?.length
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                  } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={!KeyResultList?.length}
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefineDepartment;
