import React from "react";
import { useEffect, useState } from "react";
import "../assets/css/style.css";
import "../assets/css/app.css";
import "../assets/css/app.min.css";
import "react-toastify/dist/ReactToastify.css";
import Lazy from "../Routes/Lazy";
import { ToastContainer } from "react-toastify";
import "../assets/css/app.min.css.map";
import NoInternetConnection from "../Components/comman/NoInternetConnection";

function App() {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div className="App">
      {isOnline ? <Lazy /> : <NoInternetConnection />}
      <ToastContainer style={{ zIndex: "9999999" }} />
    </div>
  );
}

export default App;
