/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import AppLayout from "../Layout/AppLayout";
import companyLogo from "../../assets/images/Icons/Pics/Icon featherimage.png";
import profileImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";

import API from "../../Api/Api";
import baseApi from "../../Api/config";
import axios from "axios";
export default function MyProfile() {
  const [profileDetials, setProfileDetails] = useState(null);
  const [imagePath1, setImagePath1] = useState(null);
  const [activeEmail,setActive]=useState(false);
  const [email,setEmail]=useState(null);
  const handleActive =()=>{
    setActive(!activeEmail);
  }
  const navigate = useNavigate();

  const getProfileDetails = () => {
    API.Profile.GetProfileDetails()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setProfileDetails(response.data.data);
          setImagePath1(response.data.data?.department?.profileImageUrl);
          setEmail(response.data.data?.department?.email);

        }
      })
      .catch((err) => {
        if(err.response.status===403){
          navigate("/");
        }
        console.log(err);
      });
  };
  useEffect(() => {
    getProfileDetails();
  }, []);

  const handleUpload = async (event) => {
    event.persist();

    if (event.target.files[0]) {
      try {
        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        const response = await axios.post(
          `${baseApi.baseurl}/organization/fileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.statusCode === 200) {
          console.log(response.data.filename); // Handle the response from the backend as needed
          updateProfileImg(response?.data?.filename);
          setImagePath1(response?.data?.filename);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const updateProfileImg = (imgPath) => {
    API.Profile.UpdateProfileImage({
      data: {
        profileImageUrl: imgPath,
        // email:email
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          // setImagePath1(`${baseApi.baseurl}/images/${response?.data?.data?.profileImageUrl}`);
          // getProfileDetails();
          setActive(false)
        }
      })
      .catch((err) => console.log(err));
  };
  console.log(profileDetials, "profileDetials");
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="progressCard">
              <div className=" welcomeHeading top-border-radius pb-5">
                <div className="d-flex align-items-center ps-3 pb-5">
                  <NavLink to="/dashboard">
                    <div className="backArrow me-2">
                      <i className="fa fa-solid fa-chevron-left textDark"></i>
                    </div>
                  </NavLink>
                  <h4 className="mb-0 textDark">My Account</h4>
                </div>
              </div>
              <div className="row position-relative">
                <div className="col-12 profileImg float-start">
                  <p className="addUserPic mx-auto mt-1 mb-1">
                    <button className="addPicIcon border-0">
                      <i className="fas fa-pen"></i>
                    </button>
                    <img
                      src={
                        imagePath1
                          ? `${process.env.REACT_APP_BASE_URL}/images/${imagePath1}`
                          : departmentImg
                      }
                      className="img-fluid profileSize"
                    />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      name="media"
                      accept="image/*"
                      onChange={(e) => handleUpload(e)}
                    />

                    <label
                      className="custom-file-label mb-0"
                      htmlFor="customFile"
                    ></label>
                  </p>
                  <h3 className="text-center textBlue font14 mb-1 pt-3 fw-600">
                    {profileDetials &&
                      profileDetials?.department &&
                      profileDetials?.department?.fullName}
                  </h3>
                  <p className="text-center font14 mb-1 textLightGrey">
                    {/* {profileDetials &&
                      profileDetials?.department &&
                      profileDetials?.department?.dept_name} */}
                    Department
                  </p>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-12">
                  <h3 className="textBlue fw-600">Company Details</h3>
                  <div className="row mt-4">
                    <div className="col-xl-6 mb-4">
                      <div className="row">
                        <div className="col-md-6 mb-3" id="profileCompanyLogo">
                          <h4 className="textLightGrey fw-600">Company Logo</h4>
                          <p className="addUserPic mt-1 mb-1">
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <img
                                src={
                                  profileDetials &&
                                    profileDetials?.organization &&
                                    profileDetials?.organization?.companyLogoUrl
                                    ? `${process.env.REACT_APP_BASE_URL}/images/${profileDetials?.organization
                                      ?.companyLogoUrl}`
                                    : companyLogo
                                }
                                className="img-fluid rounded"
                                style={{
                                  height: "86px",
                                  width: "146px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <h4 className="textLightGrey fw-600">Company Name</h4>
                          <p className="textDarkGrey">
                            {profileDetials &&
                              profileDetials?.organization &&
                              profileDetials?.organization?.organization_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <h4 className="textLightGrey fw-600">CIN</h4>
                          <p className="textDarkGrey">
                            {" "}
                            {profileDetials &&
                              profileDetials?.organization &&
                              profileDetials?.organization?.cin}
                          </p>
                        </div>
                        <div className="col-md-7 mb-3">
                          <h4 className="textLightGrey">Work Email Address</h4>
                          <div className="row d-flex align-items-center">
                            <div className="col-11" style={{overflowWrap: 'break-word'}}>
                              {/* {!activeEmail ? */}
                              <p className="textDarkGrey">
                                {" "}
                                {profileDetials &&
                                  profileDetials?.department &&
                                  profileDetials?.department?.email}
                              </p>
                              {/* :
                              <input type="email" className="form-control textDarkBlack" value={email} style={{fontSize:'12px'}} onChange={(e)=> setEmail(e.target.value)}/>} */}
                            </div>
                            {/* <div className="col-1 ps-0 pb-3" onClick={()=>handleActive()}>
                              <i className="fas fa-pen textDarkBlue">_</i>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-12">
                  <h3 className="textBlue fw-600">Company Mission & Vision</h3>
                  <div className="row mt-4">
                    <div className="col-xl-6 mb-4">
                      <h4 className="textLightGrey fw-600">Mission</h4>
                      <div className="row d-flex align-items-center">
                        <div className="col-11">
                          <p className="missionText textDarkGrey mb-0  overflow-auto text-break h-50">
                            {profileDetials &&
                              profileDetials?.organization &&
                              profileDetials?.organization?.mission}
                          </p>
                        </div>
                        {/* <div className="col-1">
                          <i className="fas fa-pen textDarkBlue">_</i>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <h4 className="textLightGrey fw-600">Vision</h4>
                      <div className="row d-flex align-items-center">
                        <div className="col-11">
                          <p className="missionText textDarkGrey mb-0  overflow-auto text-break h-50">
                            {profileDetials &&
                              profileDetials?.organization &&
                              profileDetials?.organization?.vision}
                          </p>
                        </div>
                        {/* <div className="col-1">
                          <i className="fas fa-pen textDarkBlue">_</i>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
