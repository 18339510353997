import React from "react";
import AppLayout from "../Layout/AppLayout";
export default function Objective() {

  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <h3 className="mb-0 fw-bold">Objective</h3>
            </div>
          </div>

 
        </div>
      </div>
    </AppLayout>
  );
}
