import React from "react";
import loginImage from "../../assets/images/background/login-page-png.png";

export default function LoginBanner() {
  return (
    <div className="col-lg-6 col-12 vh-100 loginBanner">
      <div className="row d-flex align-items-end h-100">
        <h2 className="text-new-black ">
          Drive Success Like <br /> Never Before With EasyOKR
        </h2>
        <img
          style={{ objectFit: "cover" }}
          src={loginImage}
          height="100%"
          alt="login page "
        />
      </div>
      {/* </div> */}
    </div>
  );
}
