import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import getAssistIcon from "../../assets/images/Icons/SVG/assistlogo.svg";
import "../../assets/css/ChatComponent.css"; // Import your CSS file for styling
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ChatComponent = ({ setIsChatVisible, chatBotValue }) => {
   const defaultQuestions = [
      "What are some best practices for achieving this key result?",
      "Help me break down this task into smaller, manageable sub-tasks.",
      "How does this task align with our overall organizational objectives?",
   ];
   const [messages, setMessages] = useState([]); // State to manage chat messages
   const [inputValue, setInputValue] = useState(""); // State to manage input field value
   const [objectiveList, setObjectiveList] = useState(null);
   // const [organizationObjectives, setOrganizationObjectives] = useState("");
   const chatContainerRef = useRef(null); // Ref for the chat container

   const postData = useSelector((state) => state.date.departmentData);
   const deptInfo = useSelector((state) => state.date.objectiveInfo);

   const navigate = useNavigate();

   useEffect(() => {
      getOrgObjectiveList();
      getChatHistory();
   }, []);

   // useEffect(() => {
   //    // Extract descriptions from ObjectiveList and store them in state
   //    if (objectiveList && objectiveList.companyDetails) {
   //       const descriptions = objectiveList.companyDetails
   //          .map((item) => item?.description)
   //          .filter((desc) => desc) // Filter out undefined or null descriptions
   //          .join(", ");
   //       setOrganizationObjectives(descriptions);
   //    console.log(objectiveList,"objectiveListobjectiveList",objectiveList.companyDetails,"descriptions",descriptions);

   //    }
      
   // }, [objectiveList]);

   const scrollToBottom = () => {
      if (chatContainerRef.current) {
         chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
   };

   useEffect(() => {
      scrollToBottom();
   }, [messages]);

   // Function to parse the response message and format it with proper HTML tags
   const parseMessage = (message) => {
      // Split the message into paragraphs by double newlines
      const paragraphs = message.split("\n\n");
      return paragraphs.map((paragraph, index) => {
         return <p key={index}>{paragraph}</p>;
      });
   };
   const sendMessage = async (e, predefinedMessage = null) => {
      if (e) {
         e.preventDefault(); // Prevent form from refreshing the page
      }
      const messageToSend = predefinedMessage || inputValue.trim(); // Use predefined message if available, otherwise use input value

      if (messageToSend !== "") {
         // Add the user message to the chat
         const newMessage = { text: messageToSend, sender: "user" };
         setMessages((prevMessages) => [...prevMessages, newMessage]);
         setInputValue(""); // Clear the input after sending
         try {
            const response = await API.chatApis.getassistAPIs({
               data: {
                  message: `As a ${postData.DeptName} department in company ${objectiveList?.about_us} where organizational objective is ${deptInfo?.objName} and departmental objective is ${deptInfo?.KeyResult} and task for this departmental objective is ${chatBotValue.objective_initiative_task} and key result for this task is ${chatBotValue.key_result}, ${messageToSend}`, key_result_id: chatBotValue._id, user_typed_question: messageToSend,},
            });
            if (response.data.statusCode === 200) {
               const reply = {
                  text: response.data.data.answer,
                  sender: "bot",
               };
               setMessages((prevMessages) => [...prevMessages, reply]);
            } else {
               console.error("Error in response:", response.data);
            }
         } catch (error) {
            console.error("Error:", error);

            const errorMessage = {
               text: "Sorry, something went wrong.",
               sender: "bot",
            };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
         }

         scrollToBottom(); // Ensure the chat scrolls to the bottom after sending the message
      }
   };

   const getOrgObjectiveList = () => {
      API.Dashboard.DashboardOrgObjectiveList()
         .then((response) => {
            if (response.data.statusCode === 200) {
               console.log(response);
               setObjectiveList(response.data.data[0]);
            }
         })
         .catch((err) => {
            if (err.response.status === 403) {
               navigate("/");
            }
            console.log(err);
         });
   };
   const getChatHistory = () => {
      const key_result_id = chatBotValue ? chatBotValue._id : undefined;
      console.log("key_result_id", key_result_id);

      API.chatApis
         .getChatMessageHistory({ key_result_id }) // Adjust the API call according to your endpoint
         .then((response) => {
            if (response.data.statusCode === 200) {
               const formattedMessages = [];

               for (let i = 0; i < response.data.data.length; i++) {
                  const item = response.data.data[i];
                  formattedMessages.push({
                     text: item.user_typed_question,
                     sender: "user",
                  });
                  formattedMessages.push({
                     text: item.answer,
                     sender: "bot",
                  });
               }

               setMessages(formattedMessages);
            } else {
               console.log("No chat history found");
            }
         })
         .catch((err) => {
            if (err.response && err.response.statusCode === 403) {
               navigate("/"); // Redirect if unauthorized
            }
            console.error("Error fetching chat history:", err);
         });
   };

   const handlePredefinedMessageClick = (message) => {
      sendMessage(null, message);
   };
   const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
         e.preventDefault(); // Prevents adding a new line
         sendMessage(e);
      }
   };

   return (
      <div className="position-relative">
         <div className={`chat-container`}>
            <div className="headerChat px-4 py-3">
               <div className="row">
                  <div className="col-8">
                     <div className="d-flex align-items-center">
                        <img src={getAssistIcon} alt="Get AI Assist" className="get-ai-assist-chat" />
                        <h5 className="ms-3 m-0" style={{ color: "#4D75DB" }}>
                           Get AI Assist
                        </h5>
                     </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                     <button
                        className="btn p-0 close-button me-2"
                        style={{ height: "0px" }}
                        onClick={() => {
                           setIsChatVisible(false);
                        }}
                     >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22">
                           <path
                              d="M5.72 5.72a.75.75 0 0 1 1.06 0L12 10.94l5.22-5.22a.749.749 0 0 1 1.275.326.749.749 0 0 1-.215.734L13.06 12l5.22 5.22a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L12 13.06l-5.22 5.22a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L10.94 12 5.72 6.78a.75.75 0 0 1 0-1.06Z"
                              fill="#bebebe"
                           ></path>
                        </svg>
                     </button>
                  </div>
               </div>
            </div>
            <div className="chat-messages p-3" style={{ maxHeight: "500px" }}>
               <div
                  className="d-flex flex-column justify-content-between align-items-start h-100vh  scroll__y"
                  ref={chatContainerRef}
               >
                  <div className="mb-2 w-100">
                     <p className="mb-2 text-center" style={{ color: "#0A1B46" }}>
                        Hey Champion!
                        <br /> Wondering what will be the next step after creating your action items?
                     </p>
                     <p className="text-center" style={{ color: "#888888" }}>
                        Here a prompt to help you...
                     </p>
                  </div>
                  {defaultQuestions.map((question, index) => (
                     <div key={index} className="userTextLast mb-2 w-100" onClick={() => handlePredefinedMessageClick(question)}>
                        <p className="mb-2 text-center" style={{ color: "#0A1B46" }}>
                           {question}
                        </p>
                     </div>
                  ))}
                  <div className="">
                     {messages.map((message, index) => (
                        <div key={index} className={`message userTextLast mb-2 w-100 ${message.sender}`}>
                           {
                              message.sender === "bot" ? (
                                 parseMessage(message.text) // Parse and render bot messages
                              ) : (
                                 <p className="mb-0">{message.text}</p>
                              ) // Render user messages as plain text
                           }
                        </div>
                     ))}
                  </div>
               </div>
            </div>

            <div className="footerChat mb-3">
               <form
                  className="message-form position-relative"
                  onSubmit={(e) => {
                     sendMessage(e);
                     const textarea = e.target.querySelector("textarea");
                     textarea.style.height = "auto";
                  }}
               >
                  <textarea
                     type="text"
                     className="py-3"
                     placeholder="Ask your queries..."
                     value={inputValue}
                     rows={1}
                     onChange={(e) => {
                        setInputValue(e.target.value);
                        e.target.style.height = "auto";
                        if (e.target.scrollHeight <= 50 * 2) {
                           e.target.style.height = `${e.target.scrollHeight}px`;
                        } else {
                           e.target.style.height = "80px";
                        }
                     }}
                     onKeyDown={handleKeyDown}
                  />
                  <button type="submit" className="btn btnSubmit">
                     <i className="fa fa-regular fa-paper-plane text-white"></i>
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
};
ChatComponent.propTypes = {
   setIsChatVisible: PropTypes.func,
   chatBotValue: PropTypes.object,
};

export default ChatComponent;
