const RouteName = {
  Auth: {
    SignUp: "/Sign-up",
    Login: "/",
    VerifyOtp: "verify-otp",
    OtpRecent: "recent-otp",
    Registration: "accept-invite",
    // NewPassword: "new-password",
    // ForgotPassword: "forgot-password",
    // PasswordReset: "password-reset",
    // LoginSuccessful: "login-successful",
  },

  Department: {
    Department: "/department",
    DepartmentDetails: "/department-details/:id/:type",
    TimelyDelivery: "/timely-delivery/:id/:type",
    // DefineDepartment: "/add-department",
    Objective: "objective",
  },

  Dashboard: {
    Dashboard: "/dashboard",
  },
  MyProfile: {
    MyProfile: "/my-profile",
  },
  Organization: {
    Organization: "/organization",
  },
  Settings: {
    Settings: "/settings",
    SettingsDetails: "/settings-details",
    SettingsDetailsEdit: "/settings-details-edit",
  },

  PageNotFound: "*",
};

export default RouteName;
